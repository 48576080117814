<template>
  <div class="about">
    <div class='container'>
      <h1>About Robin Suzan Stith</h1>
      <h5>Columbus, Ohio's Attorney at Law</h5>
    </div>
    <div class='content container'>
      <div>

          <img src='/img/photo.png' />
          I am a lawyer, a child of God and a mother. I am a native of Fort Wayne, Indiana. In 1977, 
          I graduated with Dean’s List Honors for all quarters, from Northwestern University in Evanston, 
          IL with a Bachelors of Art in both American History and Education. In 1980 I graduated with a 
          Juris Doctorate from Georgetown University Law Center in Washington, DC.
          <br /><br />
          A job offer led me to Columbus, OH in 1980. I worked in the office of Beatty and Stith where 
          I established the family law and bankruptcy divisions. In December of 1984, I opened 
          <router-link to='/'>The Law Offices of Robin S. Stith</router-link> 
          in our first location on South High Street. In 1987, 
          <router-link to='/contact'>we moved to our present location at 13 E. Kossuth Street.</router-link>
          <br /><br />
          I became a lawyer because I wanted to help those who were unjustly accused in criminal situations. 
          As I began to practice law, I discovered that I also enjoyed family law, personal injury and bankruptcy. 
          I didn’t want to be a “one trick pony” so I continued to try out every area of law that presented itself.  
          As a result, I am well rounded in the law and I am versed in many areas that other attorneys are not.
          <br /><br />
          I find Columbus to be a wonderful place to live and to raise a family. I have been a part of many 
          organizations and campaigns here.  In addition to being a solo practitioner, I have been Special 
          Counsel to Attorney General of Ohio, an Adjunct Professor at Ohio Dominican for the American 
          Paralegal Institute, a hearing Examiner for the State of Ohio Nursing Home Board, an NFL Agent, 
          the host of a radio show covering legal topics and a seminar leader teaching the ins and outs of 
          finances, divorce and estate planning.
          <br /><br />
          I have been a member of many organizations, among those; the American Bar Association, National Bar 
          Association, Columbus Bar Association, Franklin County Women Trial Lawyers, National Urban League, 
          YWCA, Alliance of Black Women, Who’s Who Among American Lawyers and Who’s Who Among American Women. 
          While I have been asked to run for judgeships in Franklin County many times, I turned them down 
          because I felt that I could be most helpful to the community as an attorney.
          <br /><br />
          I have experienced a lot of accomplishments in my life but perhaps the one that I am the most proud 
          of is my daughter, Hanani. Being a parent and a God appointed steward of a child is an honor to be 
          taken seriously. I am so proud of her and the woman that she is becoming.
          <br /><br />
          I believe that the practice of law is my ministry. I am an attorney who will take your problems to 
          heart, just like they are my own.  I am an attorney who wants to help you. I am an attorney who 
          understands you.
          <br /><br />
          <strong>I am THE attorney for you.</strong>
        <div class='cta'>
          <router-link to='/legal-advice' class='btn'>
            Contact me for legal representation
          </router-link>
        </div>
      </div>
      <div>
        <div>
          <Contact />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from '@/components/contact.vue'
export default {
  name: 'About',
  components: { 
    Contact
  }
}
</script>

<style scoped lang='scss'>
.about {
  padding-bottom: 50px;
  h1 {
    font-family: "Merriweather", serif;
    margin-bottom: 10px;
  }

  h5 {
    margin: 0px;
    padding-bottom: 10px;
    font-weight: 300;
    color: #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
  }

  img {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
  }

  .content {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;


    div:nth-child(1) {
      line-height: 1.5em;
      font-size: 15px;
      padding-right: 25px;
      text-align: justify;

      a:not(.btn) {
        font-weight: bold;
        color: #494949;
        text-decoration: none;

        &:hover {
          color: #26bac5;
        }
      }

      div.cta {
        margin-top: 25px;
      }
    }

    div:nth-child(2) {
      div {
        border-radius: 5px;
        background-color: #2F3439;
        padding: 15px;
      }
    }
  }
}

</style>
